import { useState } from "react"
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import { toast } from "react-toastify"

const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: ""
  })

  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    messageError: ""
  })
  const [successMessage, setSuccessMessage] = useState("")

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const validateForm = () => {
    let isValid = true

    // Reset errors before validating
    setErrors({ nameError: "", emailError: "", messageError: "" })

    if (!formValues.name.trim()) {
      setErrors((prev) => ({ ...prev, nameError: "Name is required" }))
      isValid = false
    }

    if (!formValues.email.trim()) {
      setErrors((prev) => ({ ...prev, emailError: "Email is required" }))
      isValid = false
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      setErrors((prev) => ({ ...prev, emailError: "Invalid email format" }))
      isValid = false
    }

    if (!formValues.message.trim()) {
      setErrors((prev) => ({ ...prev, messageError: "Message is required" }))
      isValid = false
    }

    return isValid
  }

  const handleSumbit = async (event) => {
    event.preventDefault()
    console.log("formValues :", formValues)
    if (validateForm()) {
      const url = "https://aptechmedia.com/php/send-mail.php" // Replace with your API endpoint
      const data = {
        username: formValues.name,
        email: formValues.email,
        message: formValues.message
      }

      try {
        const response = await fetch(url, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify(data),
          mode: "cors",
          credentials: "include"
        })

        if (response.ok) {
          // const responseData = await response.json()
          toast.success("Your message has been sent successfully", {
            position: "top-center",
            autoClose: 3000,
            // hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            // progress: undefined
          })
          setFormValues({
            name: "",
            email: "",
            message: ""
          })
          setErrors({ nameError: "", emailError: "", messageError: "" })
          setTimeout(() => {
            setSuccessMessage("")
          }, 2000)

        } else {
          console.error("Error:", response.statusText)
        }
      } catch (error) {
        console.error("Network error:", error)
        toast.error("Your message has not been sent successfully", {
          position: "top-center",
          autoClose: 2000,
          // hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
          // progress: undefined
        })
      }
    }
  }
  return (
    <div className="w-full bg-blue-50 py-12">
      <div className="container mx-auto px-4 md:px-8 lg:px-12 flex flex-col items-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-black mb-8 text-center">Contact Us</h1>
        <p className="text-lg text-gray-700 mb-12 text-center max-w-2xl">
          Have a question? A brilliant game idea? Or just want to say hello to the team? Drop us a line! At AptechMedia, we&apos;re always eager to hear from fellow game enthusiasts and potential partners. Let&apos;s start a conversation that could lead to the next big hit in mobile gaming!
        </p>

        <div className="w-full flex flex-wrap justify-around items-center mb-16 space-y-8 md:space-y-0">
          <div className="flex items-center space-x-4 max-w-xs">
            <div className="bg-white p-4 rounded-full">
              <FaMapMarkerAlt className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Our Office</h3>
              <p className="text-gray-700">KPIT Park, PTCL Training Center, Canal Town Peshawar, Khyber Pakhtunkhwa 25000</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 max-w-xs">
            <div className="bg-white p-4 rounded-full">
              <FaPhoneAlt className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Phone</h3>
              <p className="text-gray-700">+92-3339371781</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 max-w-xs">
            <div className="bg-white p-4 rounded-full">
              <FaEnvelope className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Email</h3>
              <p className="text-gray-700">Info@aptechmedia.com</p>
            </div>
          </div>
        </div>

        <div className="w-full bg-white p-8 rounded-lg shadow-lg mb-16">
          <h3 className="text-2xl font-semibold mb-4 text-center">Get in Touch</h3>
          {successMessage && <p className="text-green-500 text-center text-sm mb-4">{successMessage}</p>}
          <form onSubmit={handleSumbit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
              <input id="name" type="text" name="name" value={formValues.name} onChange={handleInputChange} className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500" />
              {errors.nameError && <p className="text-red-500 text-sm">{errors.nameError}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
              <input id="email" type="email" name="email" value={formValues.email} onChange={handleInputChange} className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500" />
              {errors.emailError && <p className="text-red-500 text-sm">{errors.emailError}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-700 mb-2">Message</label>
              <textarea id="message" type="text" name="message" value={formValues.message} onChange={handleInputChange} className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500" rows="5"></textarea>
              {errors.messageError && <p className="text-red-500 text-sm">{errors.messageError}</p>}
            </div>
            <button type="submit" className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact