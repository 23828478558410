import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import Home from "./pages/Home"
import Game from "./pages/Game"
import Contact from "./pages/Contact"
import Publishing from "./pages/Publishing"
import Apps from "./pages/Apps"
import Careers from "./pages/Careers"
import Privacy from "./pages/Privacy"
import Terms from "./pages/Terms"
import JobApply from "./pages/JobApply"
import JobForm from "./pages/JobForm"
import AdminLogin from "./pages/AdminLogin"
import Dashboard from "./pages/Dashboard"
import Applicants from "./pages/Applicants"

// import Footer from "./components/layout/Footer.jsx"
import Footer from "../src/components/Layout/Footer"
import Navbar from "../src/components/Layout/Navbar"
import Team from "./pages/Team"
import ProtectedRoute from "./components/auth/ProtectedRoute"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Layout = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
  </>
)

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="games" element={<Game />} />
            <Route path="contact" element={<Contact />} />
            <Route path="publishing" element={<Publishing />} />
            <Route path="apps" element={<Apps />} />
            <Route path="team" element={<Team />} />
            <Route path="careers" element={<Careers />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="terms-conditions" element={<Terms />} />
            <Route path="careers/apply" element={<JobApply />} />
            <Route path="careers/apply/:jobId" element={<JobForm />} />
          </Route>
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin" element={<ProtectedRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="applicants" element={<Applicants />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={2000} />
    </div>
  )
}

export default App